

.lightbox {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  align-items: center;

  visibility: hidden;
  opacity: 0;

  transition: opacity ease 0.4s;
}

.show {
  visibility: visible;
  opacity: 1;
}

.show_image {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}
